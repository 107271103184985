<template>
  <div v>
    <b-overlay :show="show" rounded="sm">
      <b-row>
        <b-col cols="12">
          <b-card no-body class="rounded-0">
            <b-card-header class="bg-white font-weight-bold">Sipariş Bilgileri</b-card-header>
            <b-card-body>
              <b-row>
                <b-col cols="12" md="6">
                  <div class="d-flex justify-content-between align-items-center pb-4">
                    <span>Sipariş Numarası</span>
                    <span class="font-weight-bold">{{ form.k_no }}</span>
                  </div>
                  <div class="d-flex justify-content-between align-items-center pb-4">
                    <span>Üye Adı Soyadı</span>
                    <span class="font-weight-bold">{{ form.uye_ad }} {{ form.uye_soyad }} </span>
                  </div>
                  <div class="d-flex justify-content-between align-items-center pb-4">
                    <span>Sipariş Tarih Saat</span>
                    <span class="font-weight-bold">{{ form.tarih | momentFull }}</span>
                  </div>
                  <div class="d-flex justify-content-between align-items-center pb-4">
                    <span>Ödeme Tipi</span>
                    <span class="font-weight-bold">
                      {{
                        form.odeme_yontemi == 'kapida_odeme'
                          ? 'Kapıda Ödeme'
                          : form.odeme_yontemi == 'havale_eft'
                          ? 'Banka Havalesi'
                          : 'Kredi Kartı Ödeme'
                      }}
                    </span>
                  </div>
                  <div class="d-flex flex-column pb-4" v-if="form.siparis_iptal_not">
                    <span>Sipariş İptal Nedeni</span>
                    <span class="font-weight-bold text-danger pt-2">
                      {{ form.siparis_iptal_not }}
                    </span>
                  </div>
                </b-col>
                <b-col cols="12" md="6">
                  <div class="pb-2">
                    <b-form-group label-cols="5" label="Kargo Tercihi">
                      <v-select
                        v-model="form.gonderilecek_kargo_k_no"
                        :options="kargoFirmalari"
                        :reduce="(kargo) => kargo.k_no"
                        label="baslik"
                        :clearable="false"
                        class="invoice-filter-select d-block select-size-lg"
                        style="width: 100%"
                      />
                    </b-form-group>
                  </div>
                  <div class="pb-2">
                    <b-form-group label-cols="5" label="Kargo Takip Kodu">
                      <b-form-input size="lg" class="rounded-0" v-model="form.kargo_takip_kodu" />
                    </b-form-group>
                  </div>
                  <div class="pb-2">
                    <b-form-group label-cols="5" label="Sipariş Durumu">
                      <v-select
                        v-model="form.siparis_durum"
                        :options="siparisDurumlari"
                        :reduce="(siparis) => siparis.k_no"
                        label="baslik"
                        :clearable="false"
                        class="invoice-filter-select d-block select-size-lg"
                        style="width: 100%"
                      >
                        <template slot="selected-option" slot-scope="option">
                          {{ option.icerik[defaultDil].baslik }}
                        </template>
                        <template slot="option" slot-scope="option">
                          {{ option.icerik[defaultDil].baslik }}
                        </template>
                      </v-select>
                    </b-form-group>
                  </div>
                  <div class="pb-2">
                    <b-form-group label-cols="5" label="Ödeme Durumu">
                      <v-select
                        v-model="form.tahsilat_durum"
                        :options="tahsilatDurumlari"
                        :reduce="(tahsilat) => tahsilat.value"
                        label="title"
                        :clearable="false"
                        class="invoice-filter-select d-block select-size-lg"
                        style="width: 100%"
                      />
                    </b-form-group>
                  </div>
                  <div class="pb-4">
                    <b-form-group label-cols="5" label="Sipariş Kapama Durumu">
                      <v-select
                        v-model="form.siparis_kapama"
                        :options="siparisKapamaDurum"
                        :reduce="(kapama) => kapama.value"
                        label="title"
                        :clearable="false"
                        class="invoice-filter-select d-block select-size-lg"
                        style="width: 100%"
                      />
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <div class="pb-2 text-right">
                    <b-button-group>
                      <b-button squared variant="success" @click="handlerGuncelle">
                        <i class="fad fa-pencil" /> Güncelle
                      </b-button>
                      <b-button
                        squared
                        variant="warning"
                        class="ml-1"
                        v-b-tooltip.hover="'E Posta Gönder'"
                        @click="handlerSiparisMailGonder"
                      >
                        <i class="fad fa-mail-bulk" />
                      </b-button>
                      <b-button
                        squared
                        variant="warning"
                        class="ml-1"
                        @click="openSmsModal = true"
                        v-b-tooltip.hover="'Sms Gönder'"
                      >
                        <i class="fad fa-sms" />
                      </b-button>
                      <router-link
                        class="btn btn-warning rounded-0 ml-1"
                        :to="{ name: 'kargo-fisi', params: { k_no: form.k_no } }"
                        target="_blank"
                        v-b-tooltip.hover="'Kargo Fişi Yazdır'"
                      >
                        <i class="fad fa-truck-couch" />
                      </router-link>

                      <router-link
                        class="btn btn-warning rounded-0 ml-1"
                        :to="{ name: 'siparis-fisi', params: { k_no: form.k_no } }"
                        target="_blank"
                        v-b-tooltip.hover="'Şiparişi Yazdır'"
                      >
                        <i class="fad fa-print" />
                      </router-link>
                    </b-button-group>
                    <b-button squared variant="info" class="ml-1" v-b-tooltip.hover="'İşlem Geçmişi'" @click="openModal = true">
                      <i class="fad fa-history" />
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-card no-body class="rounded-0">
            <b-card-header class="bg-white font-weight-bold">Teslimat Bilgileri</b-card-header>
            <b-card-body v-if="form.adres_bilgileri">
              <AdresItem :item="form.adres_bilgileri.kargo" />
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="12" md="6">
          <b-card no-body class="rounded-0">
            <b-card-header class="bg-white font-weight-bold">Fatura Bilgileri</b-card-header>
            <b-card-body v-if="form.adres_bilgileri">
              <AdresItem :item="form.adres_bilgileri.fatura" />
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-card no-body class="rounded-0">
            <b-card-header class="bg-white font-weight-bold">Sipariş Notu</b-card-header>
            <b-card-body>
              {{ form.sepet_notu }}
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-card no-body class="rounded-0">
            <b-card-header class="bg-white font-weight-bold d-flex justify-content-between align-items-center">
              <span>Sipariş Ürünleri</span>
              <b-button
                squared
                size="sm"
                variant="info"
                v-if="form.kur_turu != form.secilen_kur"
                @click="sabitKuraCevir = !sabitKuraCevir"
              >
                Sabit Kura Cevir</b-button
              >
            </b-card-header>
            <b-card-body>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" class="text-left font-weight-bold h6">#Ürün</th>
                    <th scope="col" class="text-right font-weight-bold h6" width="15%">Fiyat</th>
                    <th scope="col" class="text-center font-weight-bold h6" width="15%">Miktar</th>
                    <th scope="col" class="text-right font-weight-bold h6" width="15%">Kdv</th>
                    <th scope="col" class="text-right font-weight-bold h6" width="15%">Tutar</th>
                  </tr>
                </thead>
                <tbody v-if="(form.sepet_toplam && form.kur_turu == form.secilen_kur) || sabitKuraCevir">
                  <UrunSabitItem v-for="(urun, i) in form.sepet_urunler" :key="i" :urun="urun" />
                </tbody>
                <tbody v-else-if="(form.kur_sepet_toplam && form.kur_turu != form.secilen_kur) || !sabitKuraCevir">
                  <UrunKurItem v-for="(urun, i) in form.sepet_urunler" :key="i" :urun="urun" />
                </tbody>
                <UrunSabitToplam :form="form" v-if="form.kur_turu == form.secilen_kur || sabitKuraCevir" />
                <UrunKurToplam :form="form" v-else />
              </table>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
    <SiparisIslemGecmisi :gecmis="form.islem_gecmisi" :openModal="openModal" @closeModal="closeModal = $event" />
    <SmsGonder :siparis="form" :openModal="openSmsModal" @closeModal="closeSmsModal = $event" />
  </div>
</template>

<script>
import { useRouter } from '@/libs/utils';
import store from '@/store';
import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import vSelect from 'vue-select';
import { useToast } from 'vue-toastification/composition';
import AdresItem from './component/AdresItem.vue';
import UrunSabitItem from './component/UrunSabitItem.vue';
import UrunKurItem from './component/UrunKurItem.vue';
import SiparisIslemGecmisi from './component/SiparisIslemGecmisi.vue';
import SmsGonder from './component/SmsGonder.vue';
import UrunSabitToplam from './component/UrunSabitToplam.vue';
import UrunKurToplam from './component/UrunKurToplam.vue';

export default defineComponent({
  components: {
    vSelect,
    AdresItem,
    UrunSabitItem,
    UrunKurItem,
    UrunSabitToplam,
    UrunKurToplam,
    SiparisIslemGecmisi,
    SmsGonder,
  },
  setup() {
    const expo = {};
    const { route, router } = useRouter();
    const toast = useToast();
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.show = ref(false);
    expo.openModal = ref(false);
    expo.closeModal = ref(false);
    expo.openSmsModal = ref(false);
    expo.closeSmsModal = ref(false);
    expo.sabitKuraCevir = ref(false);
    expo.kargoFirmalari = ref([]);
    expo.siparisDurumlari = ref([]);
    expo.uyeAdresler = ref([]);
    expo.renkler = ref([]);
    expo.tahsilatDurumlari = ref([
      {
        value: 0,
        title: 'Tahsil Edilmedi',
      },
      {
        value: 1,
        title: 'Tahsil Edildi',
      },
      {
        value: 2,
        title: 'Beklemede',
      },
    ]);
    expo.siparisKapamaDurum = ref([
      {
        value: 'aktif',
        title: 'Sipariş Aktif',
      },
      {
        value: 'kapandi',
        title: 'Sipariş Kapandı',
      },
      {
        value: 'iptal',
        title: 'Sipariş İptal Edildi',
      },
    ]);

    expo.form = ref({
      gonderilecek_kargo_k_no: null,
      kargo_takip_kodu: null,
      siparis_durum: null,
      tahsilat_durum: 2,
      iptal_durum: false,
      siparis_kapama: false,
      islem_gecmisi: [],
    });

    const handlerGetData = async () => {
      expo.show.value = true;
      await store.dispatch('kargoTanimlaListele', { statu: true });
      await store.dispatch('siparisDurumListele', { statu: true });
      expo.kargoFirmalari.value = store.getters.getKargoTanimlari;
      expo.siparisDurumlari.value = store.getters.getSiparisDurumlari;
      expo.show.value = false;
    };

    expo.handlerAdresGetir = computed(() => {
      return (val) => {
        return expo.uyeAdresler.value.find((x) => x.k_no == val);
      };
    });

    expo.handlerRenkGetir = computed(() => {
      return (renkler, val) => {
        if (val) return renkler.find((x) => x.k_no == val);
      };
    });

    expo.handlerGuncelle = () => {
      expo.show.value = true;
      store.dispatch('eTicSiparisGuncelle', expo.form.value).then((res) => {
        if (res.data.success) {
          toast.success('Güncelleme Başarılı', { position: 'bottom-left' });
          expo.show.value = false;
        }
      });
    };

    expo.handlerKargoFisiYazdir = () => {
      router.push({ name: 'kargo-fisi' });
    };

    expo.handlerSiparisMailGonder = () => {
      expo.show.value = true;
      store.dispatch('eTicSiparisMailGonder', expo.form.value).then((res) => {
        if (res.data.success) {
          toast.success('E posta gönderildi.', { position: 'bottom-left' });
          expo.show.value = false;
        } else {
          expo.show.value = false;
        }
      });
    };

    expo.handlerSmsGonder = () => {
      expo.openSmsModal.value = true;
    };

    onMounted(async () => {
      expo.show.value = true;
      await handlerGetData();
      const k_no = route.value.params.k_no;
      if (k_no != null) {
        const siparis = store.getters.getETicaretSiparisler.find((x) => x.k_no == k_no);
        if (siparis) {
          expo.form.value = siparis;
          expo.uyeAdresler.value = siparis.uye_adresler;
          expo.renkler.value = siparis.renkler;
        } else {
          store.dispatch('eTicSiparisFindOne', k_no).then((res) => {
            const siparis = res;
            expo.form.value = siparis;
            expo.uyeAdresler.value = siparis.uye_adresler;
            expo.renkler.value = siparis.renkler;
          });
        }
      }
      expo.show.value = false;
    });

    watch(expo.closeModal, (val) => {
      if (val == false) {
        expo.openModal.value = false;
        expo.closeModal.value = false;
      }
    });

    watch(expo.closeSmsModal, (val) => {
      if (val == false) {
        expo.openSmsModal.value = false;
        expo.closeSmsModal.value = false;
      }
    });

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
