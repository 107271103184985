<template>
  <b-sidebar
    backdrop-variant="primary"
    backdrop
    shadow
    :visible="openModal"
    right
    width="25%"
    @change="(val) => $emit('closeModal', val)"
  >
    <template #header="{ hide }">
      <div class="sidebar-sepet-header">
        <h6 class="mb-0 font-weight-bold"><i class="fad fa-sms"></i> Sms Gönder</h6>
        <i class="fad fa-times-square" @click="hide" />
      </div>
    </template>
    <template #default>
      <b-overlay :show="show" rounded="sm">
        <div class="sidebar-sepet-items px-2">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
              <b-row>
                <b-col cols="12 pt-3">
                  <validation-provider name="Telefon" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group label="Sms Gönderilecek Telefon">
                      <b-form-input
                        size="lg"
                        class="rounded-0"
                        ref="telefon"
                        v-model="form.telefon"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12 pt-3">
                  <b-form-group
                    label="Hazır Sms Kalıpları"
                    description="Hazır kalıp kullanabilir veya özel mesaj yazabilirsiniz."
                  >
                    <v-select
                      v-model="form.sms_kalip"
                      :options="smsKaliplari"
                      :reduce="(kul) => kul.value"
                      label="title"
                      :clearable="false"
                      class="invoice-filter-select d-block select-size-lg"
                      @input="handlerSmsKalipChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12 pt-3">
                  <validation-provider name="Mesaj" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group label="Mesaj" description="160 karakter sonrası 2 mesaj olarak gider">
                      <b-form-textarea
                        class="rounded-0"
                        rows="4"
                        v-model="form.mesaj"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-button size="lg" squared variant="primary" block type="submit">
                    <i class="fad fa-sms" /> SMS GÖNDER
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </div>
      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import store from '@/store';
import { defineComponent, onMounted, reactive, ref, toRef, toRefs, watch } from '@vue/composition-api';
import vSelect from 'vue-select';
import { useToast } from 'vue-toastification/composition';
export default defineComponent({
  components: { vSelect },
  props: {
    openModal: [Boolean],
    siparis: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    expo.show = ref(false);
    const { siparis } = toRefs(props);
    expo.smsKaliplari = ref([]);

    expo.form = ref({
      telefon: siparis.value.uye_telefon,
      mesaj: null,
    });

    expo.handlerSmsKalipChange = (event) => {
      expo.form.value.mesaj = expo.smsKaliplari.value.find((x) => x.value == event).mesaj;
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      expo.show.value = true;
      store
        .dispatch('smsGonder', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Sms Gönderilmiştir.', { position: 'bottom-left' });
            expo.show.value = false;
          }
        })
        .catch((err) => {
          if (err.success === false) {
            toast.success(err.message, { position: 'bottom-left' });
            expo.show.value = false;
          }
        });
    };

    watch(
      siparis,
      (val) => {
        expo.form.value.telefon = val.uye_telefon;

        expo.smsKaliplari.value = [
          {
            value: 1,
            title: 'Sipariş Onaylandı Mesajı',
            mesaj: `Sayin Sayin ${val.uye_ad}  ${val.uye_soyad} ${val.k_no} nolu siparisiniz onaylanmistir. `,
          },
          {
            value: 2,
            title: 'Kargoya Verildi Mesajı',
            mesaj: `Sayin Sayin ${val.uye_ad}  ${val.uye_soyad} ${val.k_no} nolu siparisiniz kargoya teslim edilmistir. `,
          },
          {
            value: 3,
            title: 'Sipariş İptal Mesajı',
            mesaj: `Sayin ${val.uye_ad}  ${val.uye_soyad} ${val.k_no} nolu siparisiniz iptal edilmistir. `,
          },
        ];
      },
      { deep: true, immediate: true }
    );
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.sidebar-sepet-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  margin-bottom: 30px;
  // p-4 d-flex align-items-center justify-content-between border border-bottom
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
}
.sidebar-sepet-items {
  position: relative;
  padding-top: 50px;
}
.sidebar-sepet-bottom {
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: #fff;
  z-index: 10;
}
</style>
